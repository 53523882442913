<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="商户名字"/>
                </el-form-item>
                <el-form-item>
                    <tb-select ref="merchant" :options="merchantOptions"
                               @change="val => this.options.params.merchant_id = val"/>

                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.show" placeholder="显示筛选">
                        <el-option :key="1" :value="1" label="隐藏"></el-option>
                        <el-option :key="2" :value="2" label="显示"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.MerchantSetMeal.add',
                addPerm: 'league_MerchantSetMeal_add',
                editUrl: 'league.MerchantSetMeal.edit',
                editPerm: 'league_MerchantSetMeal_edit',
                delUrl: 'league.MerchantSetMeal.delete',
                delPerm: 'league_MerchantSetMeal_delete',
                listUrl: 'league.MerchantSetMeal.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null,
                    action: null,
                    merchant_id: null,
                    show: null
                },
                columns: [
                    {
                        prop: "merchant_id", label: "所属商户", required: true, type: 'select',
                        dicUrl: 'league.Merchant.list',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        formatter: row => {
                            return row.merchant ? row.merchant.name : ''
                        }
                    },
                    {prop: "name", label: "套餐名称", required: true},
                    {
                        prop: "cover", label: "套餐封面", required: true, type: 'image', width: 72,
                        html: ({row}) => {
                            return row.cover ? `<a target="_blank" href="${row.cover}"><img class="list-user-avatar" src="${row.cover}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "tag", label: "套餐标签",},
                    {prop: "sale_price", label: "套餐价格", type: 'number', required: true},
                    {prop: "market_price", label: "划线价格", type: 'number', required: true},
                    {
                        prop: "action_value",
                        label: "佣金金额",
                        type: 'number',
                        required: true
                    },
                    {
                        prop: "show",
                        label: "是否显示",
                        type: "radio",
                        dicData: [{label: '显示', value: 1}, {label: '隐藏', value: 0}],
                        value: 1,
                        formatter: row => {
                            return row.show === 1 ? '显示' : '隐藏'
                        }
                    },
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {prop: "remark", label: "备注",}
                ],
            },
            merchantOptions: {
                listUrl: 'league.Merchant.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '商户筛选'
            },
        }
    },
    activated: function () {
        this.$refs.merchant.getLists()
    },
    methods: {}
}
</script>

<style scoped>

</style>
